/* You can add global styles to this file, and also import other style files */

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

:root {
  --subSection-bg-color: grey;
  --btn-color : white;
  --btn-background-color: slategrey;
  --label-background-color:darkslategrey;
}
